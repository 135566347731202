<template>
  <div id="promo-code-input">
    <BButton
      id="promo-code"
      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
      class="rounded cursor-pointer mb-0 border-0 w-100 px-1"
      variant="outline-warning"
      @click="onTogglePopover(true)"
    >
      <span class="text-body float-left d-flex-center">
        <feather-icon
          icon="PercentIcon"
          size="20"
          class="mr-50"
        />
        <span class="text-nowrap">
          {{ promoCodes.length > 0 ? promoCodes.length : '' }}
          {{ $t('flight.promoCode') }}
        </span>
      </span>
    </BButton>

    <BPopover
      id="popover-promo-code"
      ref="refPopover"
      variant="warning"
      target="promo-code"
      placement="top"
      triggers="focus hover"
      boundary="window"
      customClass="max-w-600"
      :show.sync="showPopover"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center bg-warning">
          {{ $t('flight.promoCode') }}
          <span
            class="p-25 cursor-pointer"
            @click="onTogglePopover"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
      </template>

      <BContainer>
        <BRow
          v-for="(source) in supportSources"
          :key="source"
          class="mb-50 px-0"
        >
          <BCol
            cols="2"
            class="d-flex-center px-0"
          >
            <div>
              {{ source }}
            </div>
          </BCol>
          <BCol
            cols="8"
            class="px-50"
          >
            <vue-autosuggest
              :value="promoCodes.find(item => item.source === source)?.code ?? null"
              :suggestions="availablePromotionsAirline[source]"
              :input-props="{
                id: `promo-code-${source}`,
                class: `form-control`,
                placeholder: $t('flight.promoCode')
              }"
              :get-suggestion-value="(option) => option.item.code"
              @input="(val) => handleChange(source, val)"
              @selected="(option) => handleSelect(source, option)"
            >
              <template slot-scope="{suggestion}">
                <div
                  class="cursor-pointer"
                  style="color: black;"
                >
                  <b>{{ suggestion.item.code }}</b>
                  <div>
                    {{ suggestion.item.description }}
                  </div>
                </div>
              </template>
            </vue-autosuggest>
          </BCol>
          <BCol
            cols="2"
            class="px-0 d-flex-center"
          >
            <BButton
              :disabled="!promoCodes.find(item => item.source === source)"
              :variant="!!promoCodes.find(item => item.source === source) ? 'flat-danger' : 'flat-secondary'"
              :class="`p-50 ${!!promoCodes.find(item => item.source === source) ? 'cursor-pointer' : 'cursor-not-allowed'}`"
              @click="handleChange(source, '')"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </BButton>
          </BCol>
        </BRow>
      </BContainer>

      <!-- <div class="text-warning">
        Nhập nhiều mã giảm giá phân cách bằng dấu phẩy (<code>,</code>)
      </div> -->
    </BPopover>
  </div>
</template>

<script>
import {
  BButton,
  BContainer,
  BPopover,
  BRow,
  BCol,
  // BFormInput,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BButton,
    BContainer,
    BPopover,
    BRow,
    BCol,
    // BFormInput,
    VueAutosuggest,
  },
  props: {
    promoCodes: {
      type: [Array, null],
      default: () => [],
    },
    airlines: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()

    function handleChange(source, rawCode) {
      const code = rawCode ? (`${rawCode}`).replaceAll(' ', '') : ''
      const promoCodesToUpdate = cloneDeep(props.promoCodes)
      const airlineIndex = promoCodesToUpdate.findIndex(item => item.source === source)
      if (airlineIndex > -1) {
        if (code.length > 0) {
          promoCodesToUpdate[airlineIndex].code = code
        } else {
          promoCodesToUpdate.splice(airlineIndex, 1)
          // emit('update:airlines', props.airlines.filter(item => item !== source))
        }
      } else {
        promoCodesToUpdate.push({
          source,
          code,
        })
        // if (!props.airlines.includes(source)) {
        // emit('update:airlines', [...props.airlines, source])
        // }
      }
      emit('update:promoCodes', promoCodesToUpdate)
    }

    const handleSelect = (source, option) => {
      if (option?.item) {
        handleChange(source, option.item.code)
      }
    }

    const supportSources = ref(['QH', 'VN', 'VJ', 'VU', '1G', 'AK'])

    const availablePromotionsAirline = ref(supportSources.value.reduce((acc, source) => ({ ...acc, [source]: [] }), {}))

    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/fetchPromotionAirlineAvailable`, {}).then(res => {
      res.data.items.sort((a, b) => b.priority - a.priority).forEach(promotion => {
        const airline = promotion.airline
        if (availablePromotionsAirline.value[airline]) {
          availablePromotionsAirline.value[airline].push({ data: [promotion] })
        }
      })
      const promoCodes = supportSources.value.reduce((codes, airline) => {
        if (availablePromotionsAirline.value[airline][0]) {
          const promotion = availablePromotionsAirline.value[airline][0].data[0]
          codes.push({ source: airline, code: promotion.code })
        }
        return codes
      }, [])
      emit('update:promoCodes', promoCodes)
    })

    // ANCHOR handle popover
    const showPopover = ref(false)
    const onTogglePopover = (status = null) => {
      if (status === true || status === false) {
        showPopover.value = status
        return
      }
      showPopover.value = !showPopover.value
    }

    return {
      showPopover,
      onTogglePopover,
      supportSources,
      availablePromotionsAirline,
      handleChange,
      handleSelect,
      upperCaseFormatter,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
#promo-code-input ::v-deep {
  #autosuggest-autosuggest__results {
    .autosuggest__results {
      width: 400px
    }
  }
}
.popover.max-w-600 {
  max-width: 600px !important;
  .popover-body {
    padding: 0.5rem !important;
  }
}
</style>
